const MAX_LINES = 200;
const lines: string[] = [];

export const add = (message: string) => {
  const now = new Date().getTime();
  const line = `${now} ${message}`;
  if (lines.push(line) > MAX_LINES) {
    lines.shift();
  }
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(line);
  }
};

const clear = () => {
  lines.length = 0;
};

const getLines = () => lines;

const logger = {
  add,
  clear,
  getLines,
};

export default logger;
