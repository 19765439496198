import { toast } from 'react-hot-toast';
import { toastHeader, toastIcon } from '../toast.styles';
import Dismiss from '../assets/images/dismiss.svg';
import Success from '../assets/images/roundedCheck.svg';
import Error from '../assets/images/roundedError.svg';
import { Box, Paragraph, Flex } from 'theme-ui';

export default function showToast({
  type, // value can be either 'success' or 'error'
  text,
}: {
  type: string;
  text: string;
}) {
  toast((t) => (
    <Flex>
      {/* class success can be replaced by error */}
      <Box sx={toastIcon} className={type}>
        <img src={type === 'success' ? Success : Error} alt={type} />
      </Box>
      <Box>
        <header sx={toastHeader}>
          <Paragraph variant="paragraphBoldMd">
            {type === 'success' ? 'Success' : 'Error'}
          </Paragraph>
          <button onClick={() => toast.dismiss(t.id)}>
            <img src={Dismiss} alt="close" />
          </button>
        </header>
        <Paragraph variant="labelMd" color="secondaryLabel">
          {text}
        </Paragraph>
      </Box>
    </Flex>
  ));
}
