import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Container, IconButton, Button, ThemeUICSSObject } from 'theme-ui';
import Undo from '../assets/images/undo.svg';
import Delete from '../assets/images/delete.svg';
import Copy from '../assets/images/copy.svg';

const zipControl: ThemeUICSSObject = {
  position: 'absolute',
  width: '20px',
  height: '20px',
  right: '2',
  cursor: 'pointer',
  img: {
    width: '100%',
    height: 'auto',
  },
};

const modalDismiss: ThemeUICSSObject = {
  position: 'absolute',
  top: '6',
  right: '6',
  cursor: 'pointer',
  width: '12px',
  height: '12px',
  p: '6px',
  boxSizing: 'content-box',
  transition: '0.3s',
  img: {
    width: '100%',
    height: 'auto',
  },
  '&:hover': {
    backgroundColor: 'primaryHighlight',
    borderRadius: '50%',
  },
};

const copy: ThemeUICSSObject = {
  position: 'absolute',
  right: '2',
  top: '3',
  cursor: 'pointer',
  width: '11px',
  height: '11px',
  img: {
    width: '11px',
    height: '11px',
  },
};

export const IconContainer = ({ children, onClick }) => (
  <Container onClick={onClick}>{children}</Container>
);

export const UndoDeleteListItem = ({ onClick }) => (
  <IconButton onClick={onClick} sx={zipControl}>
    <img src={Undo} alt="undo" />
  </IconButton>
);

export const DeleteListItem = ({ onClick }) => (
  <IconButton sx={zipControl} onClick={onClick}>
    <img src={Delete} alt="delete" />
  </IconButton>
);

export const ModalDismiss = ({ onClick }) => (
  <IconButton sx={modalDismiss} onClick={onClick}>
    <img src={Delete} alt="delete" />
  </IconButton>
);

export function CopyButton({ textToCopy }) {
  const [copied, setCopied] = useState(false);
  const resetTooltip = () => {
    setCopied(false);
  };

  return (
    <>
      <CopyToClipboard text={textToCopy} onCopy={() => setCopied(true)}>
        <IconButton sx={copy}>
          <img
            src={Copy}
            alt="Copy to clipboard"
            data-for={textToCopy}
            data-tip=""
            onMouseLeave={resetTooltip}
          />
        </IconButton>
      </CopyToClipboard>
      <ReactTooltip
        id={textToCopy}
        place="top"
        effect="solid"
        getContent={() => {
          return copied ? 'Copied' : 'Copy';
        }}
      />
    </>
  );
}

export const BulkAddBtn = ({ onClick }) => (
  <Button
    sx={{
      variant: 'buttons.tertiary',
      mr: '2',
    }}
    onClick={onClick}
  >
    <span sx={{ mr: '3', height: '14px' }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0.25C7.41421 0.25 7.75 0.585786 7.75 1V6.25H13C13.4142 6.25 13.75 6.58579 13.75 7C13.75 7.41421 13.4142 7.75 13 7.75H7.75V13C7.75 13.4142 7.41421 13.75 7 13.75C6.58579 13.75 6.25 13.4142 6.25 13V7.75H1C0.585786 7.75 0.25 7.41421 0.25 7C0.25 6.58579 0.585786 6.25 1 6.25H6.25V1C6.25 0.585786 6.58579 0.25 7 0.25Z"
          fill="#050400"
        />
      </svg>
    </span>
    Bulk Add
  </Button>
);

export const AreaAddBtn = () => (
  <Button
    sx={{
      variant: 'buttons.tertiary',
      mr: '2',
    }}
  >
    <span sx={{ mr: '3', height: '14px' }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0.25C11.4142 0.25 11.75 0.585786 11.75 1V2.28169C15.9842 2.64113 19.3589 6.01581 19.7183 10.25H21C21.4142 10.25 21.75 10.5858 21.75 11C21.75 11.4142 21.4142 11.75 21 11.75H19.7183C19.3589 15.9842 15.9842 19.3589 11.75 19.7183V21C11.75 21.4142 11.4142 21.75 11 21.75C10.5858 21.75 10.25 21.4142 10.25 21V19.7183C6.01581 19.3589 2.64113 15.9842 2.28169 11.75H1C0.585786 11.75 0.25 11.4142 0.25 11C0.25 10.5858 0.585787 10.25 1 10.25H2.28169C2.64113 6.01581 6.01581 2.64113 10.25 2.28169V1C10.25 0.585786 10.5858 0.25 11 0.25ZM11 18.25C15.0041 18.25 18.25 15.0041 18.25 11C18.25 6.99594 15.0041 3.75 11 3.75C6.99594 3.75 3.75 6.99594 3.75 11C3.75 15.0041 6.99594 18.25 11 18.25ZM13.5 11C13.5 12.3807 12.3807 13.5 11 13.5C9.61929 13.5 8.5 12.3807 8.5 11C8.5 9.61929 9.61929 8.5 11 8.5C12.3807 8.5 13.5 9.61929 13.5 11ZM15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z"
          fill="#050400"
        />
      </svg>
    </span>
    Area Add
  </Button>
);

export const RadiusAddBtn = () => (
  <Button
    sx={{
      variant: 'buttons.tertiary',
      mr: '2',
    }}
  >
    <span sx={{ mr: '3', height: '14px' }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM14.1964 6.77854C14.3078 6.49998 14.2425 6.18182 14.0303 5.96967C13.8182 5.75752 13.5 5.69222 13.2215 5.80364L8.22146 7.80364C8.03088 7.87987 7.87987 8.03088 7.80364 8.22146L5.80364 13.2215C5.69222 13.5 5.75752 13.8182 5.96967 14.0303C6.18182 14.2425 6.49998 14.3078 6.77854 14.1964L11.7785 12.1964C11.9691 12.1201 12.1201 11.9691 12.1964 11.7785L14.1964 6.77854ZM7.84629 12.1537L9.07698 9.07698L12.1537 7.84629L10.923 10.923L7.84629 12.1537Z"
          fill="#050400"
        />
      </svg>
    </span>
    Radius Add
  </Button>
);
