import { Link } from 'theme-ui';
import { AreaAddBtn, RadiusAddBtn } from './Buttons';
export default function SelectionTools() {
  return (
    <>
      <Link target="_blank" href="https://www.randymajors.org/zipcodegmap">
        <AreaAddBtn />
      </Link>
      <Link
        target="_blank"
        href="https://www.freemaptools.com/find-zip-codes-inside-radius.htm"
      >
        <RadiusAddBtn />
      </Link>
    </>
  );
}
