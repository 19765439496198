import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface loadingState {
  loading: boolean;
  loadingText: string;
}

const initialState: loadingState = {
  loading: false,
  loadingText: 'Loading...',
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: (state, action: PayloadAction<string>) => {
      state.loadingText = action.payload;
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
  },
});

export const loadingSliceActions = loadingSlice.actions;
export default loadingSlice.reducer;
