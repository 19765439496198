import { Box, Paragraph, Spinner, ThemeUICSSObject } from 'theme-ui';

const Curtain: ThemeUICSSObject = {
  width: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  height: '100vh',
  bg: 'rgba(255,255,255,0.7)',
  zIndex: '999999',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    width: '6',
    height: '6',
  },
};

const Inline: ThemeUICSSObject = {
  width: '100%',
  position: 'relative',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  svg: {
    width: '4',
    height: '4',
  },
};

export default function Loading({
  curtain,
  text,
}: {
  curtain?: boolean;
  text?: string;
}) {
  return (
    <Box sx={curtain ? Curtain : Inline}>
      <Spinner variant="styles.spinner" />
      {text && (
        <Paragraph variant="text.paragraphSm" mt={3}>
          {text}
        </Paragraph>
      )}
    </Box>
  );
}
