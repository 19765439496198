import { Container } from 'theme-ui';
import Alert from './Alert';
import { AppConfig } from '../config';

const InvalidWarehouseIdError = ({
  redirect,
  showAlert = true,
  error = '',
}) => {
  if (redirect) {
    window.location.href = AppConfig.warehouse_failure_redirect_uri;
  }
  return showAlert ? (
    <Container paddingX={[4, 4, 11]} bg="muted">
      <Alert type="error" text={'An error has occured ' + error} />
    </Container>
  ) : null;
};

export default InvalidWarehouseIdError;
