import { useAppDispatch, useAppSelector } from '../hooks/store';
import { appSliceActions } from '../state/zipcode';
import { loadingSliceActions } from '../state/loading';
import { Button } from 'theme-ui';
import { useMutationSaveZipcodesMutation } from '../generated/graphql-operations';
import _ from 'underscore';
import showToast from './Notification';
import SaveZipcodesFailureModal from './SaveZipcodesFailureModal';
import airbrake from '../services/airbrake';

export default function SaveZipCodes() {
  const dispatch = useAppDispatch();
  const warehouseId = useAppSelector((state) =>
    state.zipCode.warehouse ? state.zipCode.warehouse.id : '',
  );
  const addedZipCodes = useAppSelector((state) => state.zipCode.addedZipCodes);
  const deletedZipCodes = useAppSelector(
    (state) => state.zipCode.deletedZipCodes,
  );
  const savingZipcodesFailed = useAppSelector(
    (state) => state.zipCode.savingZipcodesFailed,
  );
  const hasUnsavedChanges = useAppSelector(
    (state) => state.zipCode.hasUnsavedChanges,
  );
  const [, result] = useMutationSaveZipcodesMutation();

  async function onSubmit(event?) {
    if (event) {
      event.target.blur(); // move focus away from the 'Save Zones' button
    }

    if (addedZipCodes.length > 0 || deletedZipCodes.length > 0) {
      dispatch(loadingSliceActions.showLoading('Saving...'));
      const { data, error } = await result({
        warehouseId: warehouseId,
        addedZipcodes: _.pluck(addedZipCodes, 'zipCode'),
        deletedZipcodeIds: _.pluck(deletedZipCodes, 'id'),
      });

      dispatch(loadingSliceActions.hideLoading());
      if (data && !error) {
        showToast({ type: 'success', text: 'Zip codes successfully saved!' });
        dispatch(
          appSliceActions.onSaveSuccess(data.upsertWarehouseZipcodes?.zipcodes),
        );
      } else {
        // report it to airbrake
        airbrake.notify({
          error:
            'error response received from graphql api SaveZipcodesMutation',
          params: { data, error, warehouseId, addedZipCodes, deletedZipCodes },
        });
        dispatch(appSliceActions.onSaveFailure());
      }
    }
  }

  return (
    <>
      {savingZipcodesFailed && (
        <SaveZipcodesFailureModal visible={true} retrySave={onSubmit} />
      )}

      <Button
        disabled={!hasUnsavedChanges}
        sx={{
          variant: hasUnsavedChanges ? 'buttons.primary' : 'buttons.disabled',
        }}
        onClick={(event) => {
          onSubmit(event);
        }}
      >
        Save Zones
      </Button>
    </>
  );
}
