import Modal from '../components/Modal';
import { ModalDismiss, BulkAddBtn } from './Buttons';
import { Textarea, Box, Heading, Paragraph, Button } from 'theme-ui';
import { useGetActiveZipcodesQuery } from '../generated/graphql-operations';

import { useState, useEffect } from 'react';
import Alert from '../components/Alert';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { appSliceActions } from '../state/zipcode';
import Loading from './Loading';
import airbrake from '../services/airbrake';

const BulkAdd = () => {
  const dispatch = useAppDispatch();
  const warehouseID = useAppSelector((state) => state.zipCode.warehouse?.id);

  const [zipsUserProvided, setZipsUserProvided] = useState([]);
  const [zipsAddable, setZipsAddable] = useState([]);
  const [zipsInvalid, setZipsInvalid] = useState([]);
  const [zipsAlreadyInWH, setZipsAlreadyInWH] = useState([]);
  const [zipsInOtherWH, setZipsInOtherWH] = useState([]);

  const [dataSaved, setDataSaved] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRequiredMsg, setshowRequiredMsg] = useState(false);

  const BulkAddValidator = () => {
    const [{ fetching, data, error, operation }] = useGetActiveZipcodesQuery({
      requestPolicy: 'network-only',
      variables: { zipcodes: zipsUserProvided },
    });

    useEffect(() => {
      if (!error && data) {
        const addables: any = []; // Add the new zipcodes to redux state
        const invalids: any = [];
        const alreadyInThisWH: any = [];
        const alreadyInOtherWH: any = [];

        if (!fetching && data) {
          data.activeZipcodes?.forEach((zipcode) => {
            let zc = zipcode?.zipCode;

            if (zipcode?.warehouseId === warehouseID) {
              if (alreadyInThisWH.indexOf(zc) === -1) alreadyInThisWH.push(zc);
            } else {
              if (alreadyInOtherWH.indexOf(zc) === -1)
                alreadyInOtherWH.push(zc);

              const newZipcode = {
                zipCode: zc,
                id: zipcode?.id,
                warehouse: {
                  id: zipcode?.warehouseId,
                  name: zipcode?.warehouseName,
                },
              };
              addables.push(newZipcode);
            }
          });

          setZipsAlreadyInWH(alreadyInThisWH);
          setZipsInOtherWH(alreadyInOtherWH);

          const unassigned = [];
          zipsUserProvided.forEach((zip) => {
            // assumed to be invalid
            if (!/^([0-9]{5})$/.test(zip)) {
              if (invalids.indexOf(zip) === -1) invalids.push(zip);
            }

            // unassigned yet and thus addable
            else if (
              alreadyInOtherWH.indexOf(zip) === -1 &&
              alreadyInThisWH.indexOf(zip) === -1
            ) {
              if (unassigned.indexOf(zip) === -1) {
                unassigned.push(zip);
                addables.push({ zipCode: zip });
              }
            }
          });

          setZipsInvalid(invalids);
          setZipsAddable(unassigned);

          setDataSaved(true);
          setShowLoading(false);
          setshowRequiredMsg(false);
          if (addables.length) {
            dispatch(appSliceActions.addZipCodes(addables));
          }
        }
      }
      if (error) {
        // answer: report it to airbrake
        airbrake.notify({
          error:
            'error response received from graphql api GetActiveZipcodesQuery',
          params: { data, error, zipsUserProvided },
        });
      }
    });

    return null;
  };

  const closeModal = () => {
    setshowRequiredMsg(false);
    setDataSaved(false);
    setShowModal(false);
  };

  const addBulkZips = (event) => {
    event.preventDefault();
    if (event.target.bulkAddZipCodes.value.length) {
      const zipsUP = event.target.bulkAddZipCodes.value
        .split(/[,\s]+/)
        .filter((zip: string) => zip.trim().length > 0);
      setshowRequiredMsg(false);
      setShowLoading(true);
      setZipsUserProvided(zipsUP);
    } else {
      setshowRequiredMsg(true);
    }
  };

  const hideRequiredMsg = (event) => {
    setshowRequiredMsg(false);
  };

  return (
    <>
      <BulkAddBtn
        onClick={(event) => {
          setShowModal(true);
        }}
      />

      {showModal && (
        <Modal visible={true}>
          <ModalDismiss onClick={closeModal} />
          <Heading variant="text.headingLg" mb={6}>
            {!dataSaved ? <> Bulk add </> : <>Bulk add result</>}
          </Heading>
          {!dataSaved ? (
            !showLoading ? (
              <Box as="form" onSubmit={addBulkZips}>
                <Paragraph variant="text.labelLg" mb={7}>
                  Add 5-digit zip codes
                </Paragraph>
                <Textarea
                  name="bulkAddZipCodes"
                  id="bulkAddZipCodes"
                  className={showRequiredMsg ? 'error' : ''}
                  rows={6}
                  onFocus={hideRequiredMsg}
                />

                <Paragraph
                  variant="text.labelSm"
                  sx={{ color: 'secondaryLabel', height: '15px' }}
                  mt={1}
                >
                  {showRequiredMsg && <>Please enter zip codes</>}
                </Paragraph>

                <Box sx={{ textAlign: 'right' }} mt={6}>
                  <Button
                    variant="buttons.tertiary"
                    onClick={closeModal}
                    mr={2}
                  >
                    Cancel
                  </Button>
                  <Button variant="buttons.secondary">Add</Button>
                </Box>
              </Box>
            ) : (
              <>
                <Loading text="Adding..." curtain={false} />
                <BulkAddValidator />
              </>
            )
          ) : (
            <>
              {zipsAddable.length > 0 && (
                <Alert
                  type="success"
                  label="Zip codes added:"
                  text={zipsAddable.join(', ')}
                />
              )}

              {zipsInOtherWH.length > 0 && (
                <Alert
                  type="warning"
                  label="Added Zip codes belonging to another warehouse:"
                  showCopyIcon={true}
                  text={zipsInOtherWH.join(', ')}
                />
              )}

              {zipsInvalid.length > 0 && (
                <Alert
                  type="error"
                  label="Invalid zip codes:"
                  showCopyIcon={true}
                  text={zipsInvalid.join(', ')}
                />
              )}

              {zipsAlreadyInWH.length > 0 && (
                <Alert
                  type="error"
                  label="Zip codes already assigned to this warehouse:"
                  showCopyIcon={true}
                  text={zipsAlreadyInWH.join(', ')}
                />
              )}

              <Box sx={{ textAlign: 'right' }} mt="6">
                <Button variant="buttons.secondary" onClick={closeModal}>
                  OK
                </Button>
              </Box>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default BulkAdd;
