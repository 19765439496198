import Modal from '../components/Modal';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { Box, Button, Heading, Paragraph } from 'theme-ui';
import { appSliceActions } from '../state/zipcode';
import { ModalDismiss } from './Buttons';

const SaveZipcodesFailureModal = ({
  visible,
  retrySave,
}: {
  visible: boolean;
  retrySave: () => void;
}) => {
  const dispatch = useAppDispatch();

  const retry = (event: any) => {
    dispatch(appSliceActions.closeSaveFailureModal());
    retrySave();
  };
  const cancel = (event: any) => {
    dispatch(appSliceActions.closeSaveFailureModal());
  };

  return (
    <Modal visible={visible}>
      <ModalDismiss onClick={cancel} />
      <Heading variant="text.headingLg" mb={6}>
        Oops! Something went wrong!
      </Heading>
      <Paragraph variant="text.labelLg" mb={6}>
        An error occured while trying to save your changes.
      </Paragraph>
      <Box sx={{ textAlign: 'right' }}>
        <Button variant="buttons.ghost" onClick={cancel} mr={2}>
          Cancel
        </Button>
        <Button variant="buttons.secondary" onClick={retry}>
          Retry
        </Button>
      </Box>
    </Modal>
  );
};

export default SaveZipcodesFailureModal;
