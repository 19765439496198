import React from 'react';
import { Notifier } from '@airbrake/browser';
import logger from './logger';

const { AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY, NAME } = window.env;
/**
 * AirBrake notifier
 *
 * How to test in browser :
 * - If testing locally, change <code>disableReportingForDefaultEnv</code> to `false` in `airbrake.addFilter`
 * - Run the folllwing in browser console
 * ```
 * window.onerror(new Error("TestError: This is a test"), "lorem/ipsum/file.js", 321)
 * ```
 *
 * @type {Notifier}
 */
const airbrake = new Notifier({
  projectId: Number(AIRBRAKE_PROJECT_ID),
  projectKey: AIRBRAKE_PROJECT_KEY,
  environment: NAME,
});
/**
 * If true, the filter will disable crash reporting for that notice.
 * of in the case of process.env.NODE_ENV being "default"
 * @type {boolean}
 */
const disableReportingForDefaultEnv = NAME === 'supply-admin-dev';

airbrake.addFilter((notice) => {
  if (disableReportingForDefaultEnv) {
    return null;
  }
  Object.assign(notice.context, { audit: [...logger.getLines()] });
  logger.clear();
  return notice;
});

export default airbrake;
