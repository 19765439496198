import { Flex, Paragraph, Box, ThemeUICSSObject } from 'theme-ui';
import { CopyButton } from './Buttons';
import Error from '../assets/images/roundedError.svg';
import Success from '../assets/images/roundedCheck.svg';
import Info from '../assets/images/roundedInfo.svg';
import Warning from '../assets/images/roundedWarning.svg';

const Alertbg: ThemeUICSSObject = {
  position: 'relative',
  width: '100%',
  borderRadius: '2',
  paddingX: '4',
  paddingY: '2',
  alignItems: 'flex-start',
  border: '1px solid',
  'border-radius': '8px',
  color: 'neutralDark',
  '&.error': {
    bg: 'errorHighlight',
    borderColor: 'errorDefault',
  },
  '&.success': {
    bg: 'successHighlight',
    borderColor: 'successDefault',
  },
  '&.info': {
    bg: 'informationHighlight',
    borderColor: 'informationHighlight',
  },
  '&.warning': {
    bg: 'warningHighlight',
    borderColor: 'warningDefault',
  },
  img: {
    width: '18px',
    height: '18px',
    display: 'inline-block',
    mr: '3',
  },
};

export default function Alert({
  type, // value can be either 'success' or 'error' or 'warning' or 'info'
  text,
  label,
  showCopyIcon,
}: {
  type?: string;
  text?: string;
  label?: string;
  showCopyIcon?: boolean;
}) {
  return (
    <Flex sx={Alertbg} className={type} mb={6}>
      <img
        src={
          type === 'error'
            ? Error
            : type === 'success'
            ? Success
            : type === 'info'
            ? Info
            : Warning
        }
        alt={type}
      />
      <Box>
        {label && <Paragraph variant="text.labelMd">{label}</Paragraph>}
        {text && (
          <>
            <Paragraph variant="text.labelMd">{text}</Paragraph>
            {showCopyIcon && <CopyButton textToCopy={text} />}
          </>
        )}
      </Box>
    </Flex>
  );
}
