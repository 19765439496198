import { Flex, Paragraph, Link, Box } from 'theme-ui';
import { AppConfig } from '../config';
import { appSliceActions } from '../state/zipcode';
import { useAppDispatch, useAppSelector } from '../hooks/store';
const BreadcrumbsStyle = {};

const breadcrumb = {
  color: 'neutralDark',
  textDecoration: 'none',
  variant: 'text.labelMd',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&::after': {
    content: '"/"',
    display: 'inline-block',
    color: 'secondaryPressed',
    mr: '6px',
    ml: '6px',
  },
};

const currentPage = {
  color: 'neutralDark',
  textDecoration: 'none',
  variant: 'text.labelMd',
};

export default function Breadcrumbs() {
  const dispatch = useAppDispatch();
  const warehouse = useAppSelector((state) => state.zipCode.warehouse);
  const hasUnsavedChanges = useAppSelector(
    (state) => state.zipCode.hasUnsavedChanges,
  );

  const allWarehousesUrl = AppConfig.warehouses_url;
  const thisWarehouseUrl = allWarehousesUrl + '/' + warehouse?.pkId;

  const doConfirm = (event: any) => {
    if (hasUnsavedChanges) {
      const url = event.target.href;
      dispatch(appSliceActions.doConfirmNav(url));
      event.preventDefault();
    }
  };

  return (
    <Flex sx={BreadcrumbsStyle}>
      <Box>
        <Link href={allWarehousesUrl} onClick={doConfirm} sx={breadcrumb}>
          Warehouses
        </Link>
      </Box>
      <Box>
        <Link href={thisWarehouseUrl} onClick={doConfirm} sx={breadcrumb}>
          {warehouse?.name}
        </Link>
      </Box>
      <Box>
        <Paragraph variant="p" sx={currentPage}>
          Manage Zip Codes
        </Paragraph>
      </Box>
    </Flex>
  );
}
