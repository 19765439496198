import { Container, Flex, Box, Button, Paragraph } from 'theme-ui';
import SaveZipCodes from '../components/SaveZipCodes';
import { BulkAddBtn } from './Buttons';
import { headerWrapper, MenuButtonContainer } from './Navbar.styled';
import Back from '../assets/images/back.svg';
import { AppConfig } from '../config';
import BulkAdd from './BulkAdd';
import Breadcrumbs from './Breadcrumbs';
import SelectionTools from './SelectionTools';
export default function Navbar() {
  return (
    <header
      sx={{
        width: '100%',
        flex: 'inherit',
        variant: 'layout.header',
      }}
    >
      <Container paddingX={[4, 4, 11]} sx={headerWrapper}>
        <Flex sx={{ width: '100%' }}>
          <Breadcrumbs />
          <Box sx={MenuButtonContainer} style={{ textAlign: 'right' }}>
            <SelectionTools />
            <BulkAdd />
            <SaveZipCodes />
          </Box>
        </Flex>
      </Container>
    </header>
  );
}
