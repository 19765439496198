import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeUICSSObject } from 'theme-ui';
import { ModalDismiss } from './Buttons';

const modalLayout: ThemeUICSSObject = {
  padding: '8',
  borderRadius: '3',
  minWidth: '520px',
};

const modalPopCSS: ThemeUICSSObject = {
  zIndex: '999',
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  overflow: 'auto',
};

const modalContentPopCSS: ThemeUICSSObject = {
  backgroundColor: 'neutralLight',
  borderRadius: '3',
  zIndex: '999',
  margin: 'auto',
  maxWidth: '600px',
  padding: '8',
  width: '100%',
  height: 'auto',
  position: 'relative',
};

const modalOverlayCSS: ThemeUICSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '99',
  backgroundColor: 'neutralDark',
  opacity: '0.5',
};

const Modal = ({
  children,
  visible,
}: {
  children?: React.ReactNode;
  visible: boolean;
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    [],
  );
  return visible
    ? ReactDOM.createPortal(
        <>
          <div sx={modalPopCSS}>
            <div sx={modalContentPopCSS}>
              {/* <ModalDismiss onClick={() => {console.log("clicked")}}/> */}
              {children}
            </div>
          </div>
          <div sx={modalOverlayCSS} />
        </>,
        document.body,
      )
    : null;
};

export default Modal;
