import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryCode: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type DeleteZipcodeDeliveryRuleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveryDay: Scalars['Int'];
  zipcode: Scalars['String'];
};

export type DeleteZipcodeDeliveryRulePayload = {
  __typename?: 'DeleteZipcodeDeliveryRulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<UserError>;
};

export type DeliveryRule = {
  __typename?: 'DeliveryRule';
  deliveryDay: Scalars['Int'];
  leadTime: Scalars['Int'];
  orderDeadline: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteZipcode: DeleteZipcodeResponse;
  deleteZipcodeDeliveryRule?: Maybe<DeleteZipcodeDeliveryRulePayload>;
  upsertWarehouseZipcodes: UpsertZipcodeResponse;
  upsertZipcodeDeliveryRule: UpsertZipcodeDeliveryRulePayload;
  upsertZipcodeDeliveryWindow?: Maybe<UpsertZipcodeDeliveryWindowPayload>;
};


export type MutationDeleteZipcodeArgs = {
  input: DeleteZipcodeInput;
};


export type MutationDeleteZipcodeDeliveryRuleArgs = {
  input: DeleteZipcodeDeliveryRuleInput;
};


export type MutationUpsertWarehouseZipcodesArgs = {
  input: UpsertZipcodeInput;
};


export type MutationUpsertZipcodeDeliveryRuleArgs = {
  input: UpsertZipcodeDeliveryRuleInput;
};


export type MutationUpsertZipcodeDeliveryWindowArgs = {
  input: UpsertZipcodeDeliveryWindowInput;
};

export type Query = {
  __typename?: 'Query';
  activeZipcodes: Array<Maybe<Zipcode>>;
  warehouse: Warehouse;
  warehouses: Array<Maybe<Warehouse>>;
};


export type QueryActiveZipcodesArgs = {
  zipcodes: Array<Scalars['String']>;
};


export type QueryWarehouseArgs = {
  warehouseId: Scalars['ID'];
};

export type UpsertZipcodeDeliveryRuleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveryDay: Scalars['Int'];
  leadTime: Scalars['Int'];
  orderDeadline: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UpsertZipcodeDeliveryRulePayload = {
  __typename?: 'UpsertZipcodeDeliveryRulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deliveryRule?: Maybe<DeliveryRule>;
  errors: Array<UserError>;
};

export type UpsertZipcodeDeliveryWindowInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UpsertZipcodeDeliveryWindowPayload = {
  __typename?: 'UpsertZipcodeDeliveryWindowPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deliveryWindow?: Maybe<ZipcodeDeliveryWindow>;
  errors: Array<UserError>;
};

export type UserError = {
  __typename?: 'UserError';
  /** A description of the error */
  message: Scalars['String'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>;
};

export type Vendor = {
  __typename?: 'Vendor';
  address?: Maybe<Address>;
  id: Scalars['ID'];
  isWarehouse: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['ID'];
  name: Scalars['String'];
  pkId: Scalars['ID'];
  vendor?: Maybe<Vendor>;
  zipcodes?: Maybe<Array<Maybe<Zipcode>>>;
};


export type WarehouseZipcodesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type Zipcode = {
  __typename?: 'Zipcode';
  active?: Maybe<Scalars['Boolean']>;
  deliveryRules?: Maybe<Array<Maybe<DeliveryRule>>>;
  deliveryWindow?: Maybe<ZipcodeDeliveryWindow>;
  id: Scalars['ID'];
  warehouseId?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type ZipcodeDeliveryWindow = {
  __typename?: 'ZipcodeDeliveryWindow';
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type DeleteZipcodeInput = {
  warehouseId: Scalars['ID'];
  zipcodeIds: Array<Scalars['ID']>;
};

export type DeleteZipcodeResponse = {
  __typename?: 'deleteZipcodeResponse';
  errors: Array<UserError>;
};

export type UpsertZipcodeInput = {
  warehouseId: Scalars['ID'];
  zipcodes: Array<Scalars['String']>;
};

export type UpsertZipcodeResponse = {
  __typename?: 'upsertZipcodeResponse';
  errors: Array<UserError>;
  zipcodes?: Maybe<Array<Maybe<Zipcode>>>;
};

export type GetActiveZipcodesQueryVariables = Exact<{
  zipcodes: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetActiveZipcodesQuery = { __typename?: 'Query', activeZipcodes: Array<{ __typename?: 'Zipcode', id: string, zipCode: string, warehouseId?: string | null, warehouseName?: string | null, deliveryRules?: Array<{ __typename?: 'DeliveryRule', deliveryDay: number } | null> | null } | null> };

export type GetWarehouseInfoQueryVariables = Exact<{
  warehouseId: Scalars['ID'];
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetWarehouseInfoQuery = { __typename?: 'Query', warehouse: { __typename?: 'Warehouse', id: string, pkId: string, name: string, vendor?: { __typename?: 'Vendor', address?: { __typename?: 'Address', longitude?: number | null, latitude?: number | null } | null } | null, zipcodes?: Array<{ __typename?: 'Zipcode', id: string, zipCode: string, active?: boolean | null, deliveryRules?: Array<{ __typename?: 'DeliveryRule', deliveryDay: number } | null> | null } | null> | null } };

export type GetZipcodeDeliveryRulesQueryVariables = Exact<{
  zipcodes: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetZipcodeDeliveryRulesQuery = { __typename?: 'Query', activeZipcodes: Array<{ __typename?: 'Zipcode', id: string, zipCode: string, deliveryWindow?: { __typename?: 'ZipcodeDeliveryWindow', startTime: string, endTime: string } | null, deliveryRules?: Array<{ __typename?: 'DeliveryRule', deliveryDay: number, leadTime: number, orderDeadline: string } | null> | null } | null> };

export type MutationDeleteZipcodeDeliveryRuleMutationVariables = Exact<{
  input: DeleteZipcodeDeliveryRuleInput;
}>;


export type MutationDeleteZipcodeDeliveryRuleMutation = { __typename?: 'Mutation', deleteZipcodeDeliveryRule?: { __typename?: 'DeleteZipcodeDeliveryRulePayload', errors: Array<{ __typename?: 'UserError', path?: Array<string> | null, message: string }> } | null };

export type MutationSaveZipcodesMutationVariables = Exact<{
  warehouseId: Scalars['ID'];
  addedZipcodes: Array<Scalars['String']> | Scalars['String'];
  deletedZipcodeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type MutationSaveZipcodesMutation = { __typename?: 'Mutation', upsertWarehouseZipcodes: { __typename?: 'upsertZipcodeResponse', errors: Array<{ __typename?: 'UserError', message: string, path?: Array<string> | null }>, zipcodes?: Array<{ __typename?: 'Zipcode', id: string, zipCode: string, active?: boolean | null, deliveryRules?: Array<{ __typename?: 'DeliveryRule', deliveryDay: number } | null> | null } | null> | null }, deleteZipcode: { __typename?: 'deleteZipcodeResponse', errors: Array<{ __typename?: 'UserError', message: string, path?: Array<string> | null }> } };

export type MutationUpsertZipcodeDeliveryRuleMutationVariables = Exact<{
  input: UpsertZipcodeDeliveryRuleInput;
}>;


export type MutationUpsertZipcodeDeliveryRuleMutation = { __typename?: 'Mutation', upsertZipcodeDeliveryRule: { __typename?: 'UpsertZipcodeDeliveryRulePayload', deliveryRule?: { __typename?: 'DeliveryRule', deliveryDay: number, leadTime: number, orderDeadline: string } | null, errors: Array<{ __typename?: 'UserError', path?: Array<string> | null, message: string }> } };

export type MutationUpsertZipcodeDeliveryWindowMutationVariables = Exact<{
  input: UpsertZipcodeDeliveryWindowInput;
}>;


export type MutationUpsertZipcodeDeliveryWindowMutation = { __typename?: 'Mutation', upsertZipcodeDeliveryWindow?: { __typename?: 'UpsertZipcodeDeliveryWindowPayload', deliveryWindow?: { __typename?: 'ZipcodeDeliveryWindow', startTime: string, endTime: string } | null, errors: Array<{ __typename?: 'UserError', path?: Array<string> | null, message: string }> } | null };


export const GetActiveZipcodesDocument = gql`
    query getActiveZipcodes($zipcodes: [String!]!) {
  activeZipcodes(zipcodes: $zipcodes) {
    id
    zipCode
    warehouseId
    warehouseName
    deliveryRules {
      deliveryDay
    }
  }
}
    `;

export function useGetActiveZipcodesQuery(options: Omit<Urql.UseQueryArgs<GetActiveZipcodesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveZipcodesQuery>({ query: GetActiveZipcodesDocument, ...options });
};
export const GetWarehouseInfoDocument = gql`
    query getWarehouseInfo($warehouseId: ID!, $active: Boolean) {
  warehouse(warehouseId: $warehouseId) {
    id
    pkId
    name
    vendor {
      address {
        longitude
        latitude
      }
    }
    zipcodes(active: $active) {
      id
      zipCode
      active
      deliveryRules {
        deliveryDay
      }
    }
  }
}
    `;

export function useGetWarehouseInfoQuery(options: Omit<Urql.UseQueryArgs<GetWarehouseInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWarehouseInfoQuery>({ query: GetWarehouseInfoDocument, ...options });
};
export const GetZipcodeDeliveryRulesDocument = gql`
    query getZipcodeDeliveryRules($zipcodes: [String!]!) {
  activeZipcodes(zipcodes: $zipcodes) {
    id
    zipCode
    deliveryWindow {
      startTime
      endTime
    }
    deliveryRules {
      deliveryDay
      leadTime
      orderDeadline
    }
  }
}
    `;

export function useGetZipcodeDeliveryRulesQuery(options: Omit<Urql.UseQueryArgs<GetZipcodeDeliveryRulesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetZipcodeDeliveryRulesQuery>({ query: GetZipcodeDeliveryRulesDocument, ...options });
};
export const MutationDeleteZipcodeDeliveryRuleDocument = gql`
    mutation mutationDeleteZipcodeDeliveryRule($input: DeleteZipcodeDeliveryRuleInput!) {
  deleteZipcodeDeliveryRule(input: $input) {
    errors {
      path
      message
    }
  }
}
    `;

export function useMutationDeleteZipcodeDeliveryRuleMutation() {
  return Urql.useMutation<MutationDeleteZipcodeDeliveryRuleMutation, MutationDeleteZipcodeDeliveryRuleMutationVariables>(MutationDeleteZipcodeDeliveryRuleDocument);
};
export const MutationSaveZipcodesDocument = gql`
    mutation mutationSaveZipcodes($warehouseId: ID!, $addedZipcodes: [String!]!, $deletedZipcodeIds: [ID!]!) {
  upsertWarehouseZipcodes(
    input: {warehouseId: $warehouseId, zipcodes: $addedZipcodes}
  ) {
    errors {
      message
      path
    }
    zipcodes {
      id
      zipCode
      active
      deliveryRules {
        deliveryDay
      }
    }
  }
  deleteZipcode(
    input: {warehouseId: $warehouseId, zipcodeIds: $deletedZipcodeIds}
  ) {
    errors {
      message
      path
    }
  }
}
    `;

export function useMutationSaveZipcodesMutation() {
  return Urql.useMutation<MutationSaveZipcodesMutation, MutationSaveZipcodesMutationVariables>(MutationSaveZipcodesDocument);
};
export const MutationUpsertZipcodeDeliveryRuleDocument = gql`
    mutation mutationUpsertZipcodeDeliveryRule($input: UpsertZipcodeDeliveryRuleInput!) {
  upsertZipcodeDeliveryRule(input: $input) {
    deliveryRule {
      deliveryDay
      leadTime
      orderDeadline
    }
    errors {
      path
      message
    }
  }
}
    `;

export function useMutationUpsertZipcodeDeliveryRuleMutation() {
  return Urql.useMutation<MutationUpsertZipcodeDeliveryRuleMutation, MutationUpsertZipcodeDeliveryRuleMutationVariables>(MutationUpsertZipcodeDeliveryRuleDocument);
};
export const MutationUpsertZipcodeDeliveryWindowDocument = gql`
    mutation mutationUpsertZipcodeDeliveryWindow($input: UpsertZipcodeDeliveryWindowInput!) {
  upsertZipcodeDeliveryWindow(input: $input) {
    deliveryWindow {
      startTime
      endTime
    }
    errors {
      path
      message
    }
  }
}
    `;

export function useMutationUpsertZipcodeDeliveryWindowMutation() {
  return Urql.useMutation<MutationUpsertZipcodeDeliveryWindowMutation, MutationUpsertZipcodeDeliveryWindowMutationVariables>(MutationUpsertZipcodeDeliveryWindowDocument);
};