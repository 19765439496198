export const headerWrapper = {
  alignItems: 'center',
  marginBottom: '4',
  width: '100%',
};

export const MenuButtonContainer = {
  alignItems: 'end',
  flex: '1',
};
